<template>
  <div class="page">
    <all-order-type></all-order-type>
  </div>
</template>

<script>
import AllOrderType from './allOrderType'
  export default {
    name: 'OrderList',
    components: {
      AllOrderType
    },
    data() {
      return {
      };
    },
    created () {
    },
    methods: {
    },
  }
</script>

<style scoped>
</style>